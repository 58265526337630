import PropTypes from "prop-types";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import imagesMetaData from "@/../.sourceflow/image_metadata.json";

/**
 *
 * @param className
 * @param img
 * @param size
 * @param alt
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function Image({ className, img, size, alt }) {
  if (!img) {
    return null;
  }

  if (typeof img === "string") {
    return <SourceFlowImage className={className} src={img} size={size} alt={alt} />;
  }

  if (typeof img === "object") {
    return (
      <SourceFlowImage
        className={className}
        path={img.path ?? null}
        src={img.src ?? null}
        size={size}
        alt={alt}
        imagesMetaData={imagesMetaData}
      />
    );
  }

  return null;
}

Image.defaultProps = {
  className: "",
  img: null,
  size: null,
  alt: "",
};

Image.propTypes = {
  className: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      path: PropTypes.string,
      src: PropTypes.string,
    }),
  ]),
  size: PropTypes.string,
  alt: PropTypes.string,
};
